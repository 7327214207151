/* Alert STYLE
-------------------------------*/
.alert-modal {
    /* modal container fixed across 50% screen */
    position: fixed;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    /* z-index must be higher than .modal-background */
    z-index: 1000;
    margin: auto;

    /* enables scrolling for tall modals */
    overflow: auto;

}

.alert-modal-body {
    margin: 40px;
    align-content: center;
    width: 90%
}

.alert-modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;
    
    /* z-index must be below .modal and above everything else  */
    z-index: 900;
}

body.alert-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}